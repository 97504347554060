<template>
  <div id="account-details" role="tabpanel" aria-labelledby="account-details-tab">
    <div class="myaccount-details">
      <form action="#" class="torress-form">
        <div class="torress-form-inner">
          <!--<div class="single-input single-input-half">
            <label for="account-details-firstname">User avatar</label>
            <input type="text"  id="account-details-firstname">
          </div>-->
         <!-- <div class="single-input single-input-half">
            <label for="account-details-lastname">Nickname</label>
            <input type="text" id="account-details-lastname">
          </div>-->
          <div class="single-input">
            <label for="account-details-email">Email*</label>
            <input type="email" :value="userinfo.email" disabled id="account-details-email">
          </div>

          <!--<div class="single-input">
            <button class="torress-btn torress-btn_dark" type="submit"><span>Save
                Changes</span></button>
          </div>-->
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
          userinfo:this.$store.state.user
      }
    },
/*
    created() {
        console.log(this.$store.state.user)
    },
    computed: {

    },
    methods: {

    }*/
  }
</script>

